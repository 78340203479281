// Import Vue
import { createApp } from 'vue';

// Import Framework7
import Framework7, { Dom7 } from 'framework7/lite-bundle';

// Import Framework7-Vue Plugin
import Framework7Vue, { registerComponents, f7, f7ready } from 'framework7-vue/bundle';

// Import Framework7 Styles
import 'framework7/framework7-bundle.css';

// Import Icons and App Custom Stylesnpm
import '@/css/icons.css';
import '@/css/app.scss';

// Import App Component
import App from "@/App.vue";


// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);


let app = createApp(App);

// global vars
app.config.globalProperties.$window = window;

// Register Framework7 Vue components
registerComponents(app);

// Mount the app
app.mount('#app');

// F7 可使用時
f7ready(() => {
    // Framework7's initialized instance

    // F7 相關全域變數
    app.config.globalProperties.$f7 = f7;
    // 給舊模組mobile.js使用
    window.f7 = f7;
    window.$$ = Dom7;
})


