import { baseGet } from '@/js/services/baseService';
export const useProfile = () => {
  // isLoggedIn 1 已登入 2未登入
  const isLoggedIn = ref(0);
  const userId = ref(null);
  const name = ref(null);
  baseGet('/get_liff_id').then(res => {
    if (res.data.liff_id) {
      liff.init({
        liffId: res.data.liff_id
      }).then(() => {
        if (liff.isLoggedIn()) {
          liff.getProfile().then(data => {
            console.log('getProfile', data);
            isLoggedIn.value = 1;
            userId.value = data?.userId || undefined;
            name.value = data?.displayName || undefined;
          });
        } else {
          isLoggedIn.value = 2;
        }
      });
    } else {
      isLoggedIn.value = 2;
    }
  }).catch(() => {
    isLoggedIn.value = 2;
  }); // setTimeout(() => {
  //   isLoggedIn.value = 1
  //   userId.value = 'testId'
  //   name.value = 'testName'
  // }, 1000);

  return {
    isLoggedIn,
    userId,
    name
  };
};